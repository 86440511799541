import { es as vuetifyES  } from 'vuetify/locale'
import { en as vuetifyEN  } from 'vuetify/locale'
//import { de as vuetifyDE  } from 'vuetify/locale'

export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    es: {
      $vuetify: vuetifyES
    },
    en: {
      $vuetify: vuetifyEN
    },
    /*de: {
      $vuetify: vuetifyDE
    }*/
  }
}))
